<template>
  <footer>
    &copy; {{ new Date().getFullYear() }}, Tichalinka.cz

    <div class="tw-sr-only">
      Vytvořil
      <a
        href="https://davidvesely.cz"
        title="David Veselý"
      >David Veselý</a> a <a
        href="https://silentdevs.com"
        title="Silent Devs"
      >Silent Devs s.r.o.</a>
    </div>
  </footer>
</template>

<style scoped>
footer {
  @apply tw-text-muted;
  text-align: center;
  font-size: 13px;
  line-height: 1.5;
  margin-bottom: 30px;
}
</style>
