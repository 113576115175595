<template>
  <div class="tw-flex tw-items-center tw-gap-1 tw-justify-center lg:tw-justify-end">
    <template v-if="useRoute().name?.includes('register')">
      <el-tooltip
        :content="`${$t('PublicCta.haveAccount', {}, { locale: 'cs' })} ${$t('PublicCta.login', {}, { locale: 'cs' })}`"
        placement="left"
        :disabled="$i18n.locale !== 'ua'"
      >
        <span>
          <span>{{ $t('PublicCta.haveAccount') }}&nbsp;</span>
          <nuxt-link :to="localePath('/login')">
            {{ $t('PublicCta.login') }}
          </nuxt-link>
        </span>
      </el-tooltip>

    </template>
    <template v-else>
      <el-tooltip
        :content="`${$t('PublicCta.doNotHaveAccount', {}, { locale: 'cs' })} ${$t('PublicCta.register', {}, { locale: 'cs' })}`"
        placement="left"
        :disabled="$i18n.locale !== 'ua'"
      >
        <span>
          <span>{{ $t('PublicCta.doNotHaveAccount') }}&nbsp;</span>
          <nuxt-link :to="localePath('/register')">
            {{ $t('PublicCta.register') }}
          </nuxt-link>
        </span>
      </el-tooltip>
    </template>
  </div>
</template>
