<template>
  <div
    class="Emergency"
    @click="useDialogStore().open('confirmEmergency')"
  >
    <div class="EmergencyInner">
      <h3>SOS linka</h3>
      <button>
        <div class="IconEmergencyWrapper">
          <icon-emergency />
        </div>
        Zavolat SOS
      </button>
      <p>Hasiči, policie, sanitka</p>
    </div>
  </div>
</template>

<style scoped>
.Emergency {
  width: 30%;
  height: 100vh;
  min-height: 100%;
  min-height: min-content;
  @apply tw-bg-primary-500;
  cursor: pointer;

  display: none;

  @screen md {
    display: block;
  }

  @screen lg {
    width: 426px;
  }

  &Inner {
    position: fixed;
    @apply tw-bg-primary-500;
    width: 30%;
    height: 100%;
    right: 0;

    @screen lg {
      width: 426px;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    text-align: center;
  }

  h3 {
    @apply tw-text-white tw-font-semibold;
    margin-bottom: 20px;
  }

  button {
    transition: all .3s cubic-bezier(.645,.045,.355,1);
    @apply tw-bg-danger;
    box-shadow: 0 8px 20px 0 rgb(0 0 0 / 0.15);
    border-radius: 12px;
    @apply tw-text-white;
    width: 155px;
    height: 128px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .IconEmergencyWrapper {
      @apply tw-bg-white;
      @apply tw-text-danger-500;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 8px;
    }

    &:hover {
      box-shadow: 0 8px 25px 0 rgb(0 0 0 / 0.25);
    }
  }

  p {
    opacity: 0.6;
    font-size: 16px;
    color: #FFF;
  }
}
</style>
